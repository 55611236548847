:root {
  --primary-color: #0d0430;
  --secondary-color: #030319;
}

#BODY {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100vh;
  max-width: 480px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.678), rgba(0, 0, 0, 0)),
    url("https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTEwL2pvYjEzNDQtYmFja2dyb3VuZC0wMi5qcGc.jpg");
  background-size: cover;
}

.authBanner {
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
}

.main-route {
  flex: 1;
}

.footer-new {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #16064c;
  padding: 0.8rem 0rem;
}

.login-btn-new {
  border: none;
  outline: none;
  border-radius: 10rem;
  padding: 0.4rem 1.2rem;
  font-size: 0.78rem;
}

.profile-name {
  color: #ffffffe0;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.6px;
}

.profile-border {
  border-top: 1px dotted rgba(255, 255, 255, 0.233);
}

.profile-amount {
  font-size: 0.7rem;
  letter-spacing: 0.08rem;
}

.profile-wallet {
  font-size: 0.8rem;
  letter-spacing: 0.04rem;
}

.profile-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2;
  width: 50%;
  border-radius: 5px;
  background-color: #20096d65;
  padding: 0.3rem 0rem;
  cursor: pointer;
  transition-duration: 500ms;
}

.profile-point:hover {
  scale: 1.01;
}

.login-new-divide {
  position: absolute;
  transform: translateY(10%);
  border-top: 1px solid rgba(128, 128, 128, 0.171);
}

.login-or {
  z-index: 0;
  background-color: #fff;
  font-size: 0.9rem;
  width: 4rem;
}

.spinner-border {
  width: 25px;
  height: 25px;
}

.spinner-style {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0px;
  background-color: #110241fb;
}

.commonModal {
  z-index: 2;
  height: 100%;
  background-color: red;
  outline: none;
}

.commonModal-min {
  position: absolute;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 480px;
  min-height: 20%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
}

.wallet-new {
  width: 100%;
  /* max-width: 49%; */
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  color: inherit;
  text-decoration: none;
}

.social-image {
  width: 80px;
  height: 80px;
  object-fit: fill;
}

input[id="nofocus"]::placeholder {
  font-size: 0.75rem;
}

.overlay-style {
  background-color: red;
}

.bet-action-btn {
  width: 100%;
  max-width: 480px;
  position: fixed;
  left: 50%;
  bottom: 16px;
  padding: 0rem 1rem;
  transform: translateX(-50%);
}

.primary-color {
  background-color: #6b35aa;
}

.text-purple {
  color: #9642f7;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #6c36ab;
}

/* Livechat */
.lcmain {
  position: fixed;
  bottom: 6rem;
  right: 0.5rem;
  z-index: 10;
}

.lciframe {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 480px;
  background-color: #16064c;
}

.lciframe.open {
  transform: translate(0%, 0%);
  transition: all 300ms ease-in-out;
}

.lciframe.close {
  opacity: 0;
  transform: translate(100%, 0%);
  transition: all 300ms ease-in-out;
}

.lclose {
  height: 3rem;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  background-color: #3f51b5;
  justify-content: space-between;
}

.livechatiframe {
  width: 100%;
  height: 100%;
}

.pkMain {
  background-color: #000000;
  position: fixed;
  inset: 0;
}

.pkCongrat {
  font-size: 3rem;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

.animate-pk {
  font-size: 2rem;
  position: fixed;
  transform: translate(-50%, 50%) scale(1);
  animation: pk_animate 2s linear infinite alternate;
}

@keyframes pk_animate {
  to {
    transform: translate(-50%, 50%) scale(1.8);
  }
}

@media (max-width: 600px) {
  .pkCongrat {
    font-size: 2.5rem;
  }
  .animate-pk {
    font-size: 1.5rem;
  }
}
